import React from 'react';
// import 'font-awesome/css/font-awesome.min.css';
import { Dialog, Form, Input, Button, Message, MessageBox, Loading } from 'element-react';
import 'element-theme-default';
import config from './config'
import $ from 'jquery';
import './App.css';

export default class App extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        dataLoaded: false,
        data:[],
        dialogVisible3: false,
        title:'新增',
        pid: 0,
        id: 0,
        form: {
          url_name: '',
          url: '',
          image: '',
          px: '',
        },
        childrenData: [],
        hoverVisit: false,
        hoverIndex: null,

        imageBase64:'',
        isLoad: false,
        refushChild: false,
      }
  }

  componentDidMount(){
    if (!this.state.dataLoaded) {
      this.get_data();
      this.setState({ dataLoaded: true });
    }
  }
  
  get_data = () => {
    this.setState({isLoad: true})
    $.get(`${config.api_url}/api/kbgl/get_myurl`, (res)=>{
      if (this.state.refushChild) {
        let tmpData = res.DATA.find(it =>it.id === this.state.pid)
        this.setState({childrenData: tmpData.list})
      }
      this.setState({data: res.DATA, isLoad: false})
    }).catch(err => {
      this.setState({isLoad: false},()=>{
        Message({
          type: 'error',
          message: err.responseJSON.RTN_MSG,
          duration: 5000,
          customClass: 'msgZindex'
        });
      })
    })
  }

  isTiao = (item) => {
    if (item.url_name === '新增') {
      // 新增根节点的
      this.setState({ dialogVisible3: true, title: '新增', pid: 0 })
    } else if (!item.list || item.list.length === 0){
      window.open(item.url)
    } else {
      this.setState({childrenData: item.list},()=>{
        $("#diBlack1").css('display', 'flex')
        // $("#diBlack2").css('display', 'flex')
        // $("#diBlack2").fadeIn("slow");
        $("#diBlack2").slideDown("slow");
      })
    }
    
  }

  // form数据双向绑定
  onChange(key, value) {
    this.setState({form: {...this.state.form, [key]: value}})
    // this.state.form[key] = value;
    this.forceUpdate();
  }

  Clear = () => {
    this.setState({form: {url_name: '', url: '',image: '',px: ''}})
  }

  // 提交form表单
  Tijiao = () => {
    this.setState({isLoad: true})
    const data = this.state.form
    data.pid = this.state.pid
    if (this.state.title === "编辑") {
      data.id = this.state.id
      $.post(`${config.api_url}/api/kbgl/up_myurl`, data, (res)=>{
        console.log(121212);
        if (res.RTN_CODE === "200") {
          this.setState({isLoad: false})
          Message({
            type: 'success',
            message: res.RTN_MSG
          });
          let tmp = false
          if (this.state.pid !== 0) {
            // 通知刷新childenData
            tmp = true
          }
          this.setState({dialogVisible3: false, refushChild: tmp}, ()=>{this.get_data();this.Clear()})
        } else {
          this.setState({isLoad: false},()=>{
            Message({
              type: 'error',
              message: res.RTN_MSG
            });
          })
        }
      }).catch(err => {
        this.setState({isLoad: false},()=>{
          Message({
            type: 'error',
            message: err.responseJSON.RTN_MSG,
            duration: 5000,
            customClass: 'msgZindex'
          });
        })
      })
    } else {
      $.post(`${config.api_url}/api/kbgl/add_myurl`, data, (res)=>{
        this.setState({isLoad: false})
        if (res.RTN_CODE === "200") {
          Message({
            type: 'success',
            message: res.RTN_MSG
          });
          this.setState({dialogVisible3: false}, ()=>{this.get_data();this.Clear()})
        } else {
          Message({
            type: 'error',
            message: res.RTN_MSG
          });
        }
        this.setState({isLoad: false})
      }).catch(err => {
        this.setState({isLoad: false},()=>{
          Message({
            type: 'error',
            message: err.responseJSON.RTN_MSG,
            duration: 5000,
            customClass: 'msgZindex'
          });
        })
      })
    }
  }
  
  // 新增编辑删除的 菜单选项
  handleCommand(command) {
    switch (command.slice(0,3)) {
      case 'add':
        this.addChild(command.slice(3,))
        break;
      case 'edi':
        let item = this.findItemById(this.state.data, parseInt(command.slice(3,)))
        this.edit(item)
        break;
        case 'del':
          let item1 = this.findItemById(this.state.data, parseInt(command.slice(3,)))
        this.del(item1)
        break;
      default:
        break;
    }
  }

  // 通过id寻找信息
  findItemById = (data, id) => {
    for (let item of data) {
      if (item.id === id) {
        this.setState({form: {url_name:item.url_name, url:item.url,image: item.image,px: item.px} });
        return item;
      }
      if (item.list && item.list.length > 0) {
        let tmp = this.findItemById(item.list, id);
        if (tmp) {
          return tmp
        }
      }
    }
  }

  addChild = (item) => {
    this.setState({ dialogVisible3: true, title: '新增下级',pid: parseInt(item) })
  }
  
  edit = (item) => {
    const {pid, id} = item
    this.setState({dialogVisible3: true, title: '编辑',pid, id})
  }

  del = (item) => {
    let editData = item
    MessageBox.confirm('此操作将永久删除该记录, 是否继续?', `提示（${editData.url_name}）`, {
      type: 'warning'
    }).then(() => {
      $.post(`${config.api_url}/api/kbgl/del_myurl`, {id: parseInt(item.id)}, (res)=>{
        if (res.RTN_CODE === "200") {
          Message({
            type: 'success',
            message: '删除成功!'
          });
          $("#diBlack1").css('display', 'none')
          $("#diBlack2").slideUp("fast");
          this.setState({dialogVisible3: false}, ()=>this.get_data())
        } else {
          Message({
            type: 'error',
            message: res.RTN_MSG
          });
        }
      })
    }).catch(() => {
      Message({
        type: 'info',
        message: '已取消删除'
      });
    });
  }

  handleMouseEnter = (index) => {
    this.setState({ hoverVisit: true, hoverIndex: index });
    // this.setState({ hoverVisit: true});
  }

  // 鼠标离开时隐藏 Dropdown
  handleMouseLeave = () => {
    this.setState({ hoverVisit: false });
  }

  handleImageUpload = (event) => {
    const file = event.target.files[0]; // 获取用户选择的文件
    const reader = new FileReader();
    reader.onload = (e) => {
      const base64String = e.target.result; // 获取Base64编码的图片
      this.setState({form: { ...this.state.form, image: base64String }});
    };
    reader.readAsDataURL(file); // 将文件读取为Base64编码
  };


  render() {
    return (
      <>
      <Loading fullscreen={true} style={{display: this.state.isLoad ? 'block' : 'none'}} text='加载中...' />
      <div className='rootBody'>
        <div style={{height: '80px', lineHeight: '80px',display: 'flex', flexDirection: 'column'}}>
          <h1 style={{color: '#00fbfe',letterSpacing: '10px', fontSize: '40px'}}>中南民族大学水电管理数字化平台</h1>
        </div>
        <div style={{flex: 1,overflow: 'auto',marginTop:'40px',paddingTop:'70px',width: '82%'}}>
          <div id="containOnce" style={{display: 'flex',width:'100%',flexWrap: 'wrap', justifyContent: 'center'}}>
            { this.state.data.map((item, index)=>{
              return <div key={index} className="once" onClick={()=>{this.isTiao(item)}} onMouseEnter={()=>{this.handleMouseEnter(item.id)}} onMouseLeave={()=>{this.handleMouseLeave(item.id)}}>
                <div style={{display: 'flex',flexDirection: 'column', alignItems:'center'}}>
                  <div className="circle-container">
                      <div className="circle circle-inner">
                              <div className="icon">
                                <img style={{width: '100px', height: '100px'}} src={item.image} alt="" />
                              </div>
                      </div>
                      <div className="circle circle-middle"></div>
                  </div> 
                  <span style={{color: 'white',fontSize: '20px',textAlign: 'center',marginTop: '10px'}}>{item.url_name}</span>
                </div>
              </div>
            })}
          </div>
        </div>

        <Dialog
          title={this.state.title}
          closeOnClickModal={false}
          visible={ this.state.dialogVisible3 }
          onCancel={ () => this.setState({ dialogVisible3: false },()=>{this.Clear()}) }
        >
          <Dialog.Body>
            <Form model={this.state.form}>
              <Form.Item label="名称" labelWidth="120">
                <Input onChange={this.onChange.bind(this, 'url_name')} value={this.state.form.url_name}></Input>
              </Form.Item>
              <Form.Item label="跳转网址" labelWidth="120">
                <Input onChange={this.onChange.bind(this, 'url')} value={this.state.form.url}></Input>
              </Form.Item>
              <Form.Item label="同级排序" labelWidth="120">
                <Input onChange={this.onChange.bind(this, 'px')} value={this.state.form.px}></Input>
              </Form.Item>
              <Form.Item label="图标" labelWidth="120">
                  <div>
                    <input type="file" accept="image/*" onChange={this.handleImageUpload} />
                    {this.state.form.image && (
                      <img style={{width: '140px'}} src={this.state.form.image} alt="上传图标" />
                    )}
                  </div>
              </Form.Item>
            </Form>
          </Dialog.Body>

          <Dialog.Footer className="dialog-footer">
            <Button onClick={ () => this.setState({ dialogVisible3: false },()=>{this.Clear()}) }>取 消</Button>
            <Button type="primary" onClick={ () => this.Tijiao() }>确 定</Button>
          </Dialog.Footer>
        </Dialog>

        {/* 自定义的子按钮的底部遮罩 */}
        <div id="diBlack1" onClick={()=>{
              $("#diBlack1").css('display', 'none')
              // $("#diBlack2").css('display', 'none')
              $("#diBlack2").slideUp("fast");
        }} style={{backgroundColor: 'white',zIndex:14,opacity: '0.3',width: '100%',height: '100%',position: 'absolute',display: 'none'}}>
        </div>
        <div id="diBlack2" className="tanDialog" style={{display: 'none'}}>
        <div id="containOnce" style={{display: 'flex',flexWrap: 'wrap', justifyContent: 'space-around', width: '100%'}}>
          {this.state.childrenData.map((item, index)=>{
                return <div key={index} className="once" onClick={()=>{this.isTiao(item)}} onMouseEnter={()=>{this.handleMouseEnter(item.id)}} onMouseLeave={()=>{this.handleMouseLeave(item.id)}}>
                  <div style={{display: 'flex',flexDirection: 'column', alignItems:'center'}}>
                    <div className="circle-container">
                        <div className="circle circle-inner">
                                <div className="icon">
                                  <img style={{width: '100px', height: '100px'}} src={item.image} alt="" />
                                </div>
                        </div>
                        <div className="circle circle-middle"></div>
                    </div> 
                    <span style={{color: 'white',fontSize: '20px',textAlign: 'center',marginTop: '10px'}}>{item.url_name}</span>
                  </div>
                </div>
              })}
            </div>
        </div>
        </div>
      </>
    )
  }
}
